import styled from 'styled-components/macro';
import { Dispatch, SetStateAction, useState } from 'react';
import WarningFilled from '@ant-design/icons/WarningFilled';
import { FormConfig, renderFormConfig } from '../../../../utils/form';
import Input from '../../../../ui/forms/Input';
import Checkbox from '../../../../ui/forms/Checkbox';
import PostcodeInput from '../../../school/create-school/PostcodeInput';
import { ButtonDark, ButtonLightGray } from '../../../../ui/Button';
import FindYourAddress from '../../../../ui/forms/FindYourAddress';
import InputWrap from '../../../../ui/forms/InputWrap';

interface DebitDetailsFormProps {
  formikInstance: any;
  onButtonClickHandler?: Dispatch<SetStateAction<number>>;
  isEdit?: boolean;
  isEditPage?: boolean;
  isDirectDebit?: boolean;
  setIsDirectDebit?: Dispatch<SetStateAction<boolean>>;
}

export default function DebitDetailsForm({
  formikInstance,
  onButtonClickHandler,
  isEditPage = false,
  isEdit = true,
  isDirectDebit,
  setIsDirectDebit,
}: DebitDetailsFormProps) {
  const [bankUnhookExceptionCheck, setBankUnhookExceptionCheck] =
    useState(false);
  const formConfig: FormConfig = [
    {
      title: 'Title',
      name: 'title',
      component: StyledInput,
      props: {
        disabled: !isEdit,
        placeholder: 'Mr',
      },
    },
    {
      title: 'First Name',
      name: 'firstName',
      isRequired: true,
      component: StyledInput,
      props: {
        disabled: !isEdit,
        placeholder: 'First Name',
      },
    },
    {
      title: 'Last Name',
      name: 'lastName',
      component: StyledInput,
      isRequired: true,
      props: {
        disabled: !isEdit,
        placeholder: 'Last Name',
      },
    },
    {
      title: 'Postcode',
      name: 'postcode',
      component: StyledPostcodeInput,
      isRequired: true,
      props: {
        disabled: !isEdit,
        placeholder: 'Postcode of contact address',
      },
    },
  ];

  const formConfig2: FormConfig = [
    {
      title: 'Address line 1',
      name: 'address1',
      component: StyledInput,
      isRequired: true,
      props: {
        disabled: !isEdit,
        placeholder: 'First line of contact address',
      },
    },
    {
      title: 'Address line 2',
      name: 'address2',
      component: StyledInput,
      props: {
        disabled: !isEdit,
        placeholder: 'Optional',
      },
    },
    {
      title: 'Address line 3',
      name: 'address3',
      component: StyledInput,
      props: {
        disabled: !isEdit,
        placeholder: 'Optional',
      },
    },
    {
      title: 'Town',
      name: 'town',
      component: StyledInput,
      isRequired: true,
      props: {
        disabled: !isEdit,
        placeholder: 'Required',
      },
    },
    {
      title: 'County',
      name: 'county',
      component: StyledInput,
      isRequired: true,
      props: {
        disabled: !isEdit,
        placeholder: 'Required',
      },
    },
    {
      title: 'Country',
      name: 'country',
      component: StyledInput,
      isRequired: true,
      props: {
        disabled: !isEdit,
        placeholder: 'Required',
      },
    },
    {
      title: 'Telephone number',
      name: 'phoneNumber',
      component: StyledInput,
      isRequired: true,
      props: {
        disabled: !isEdit,
        placeholder: 'Required',
      },
    },
    {
      title: 'Email',
      name: 'email',
      component: StyledInput,
      isRequired: true,
      props: {
        disabled: !isEdit,
        placeholder: 'Donor email',
      },
    },
  ];

  const formConfig3: FormConfig = [
    {
      title: 'Name(s) of account holder(s)',
      name: 'bankAccountName',
      component: StyledInput,
      isRequired: true,
      props: {
        disabled: !isDirectDebit,
        placeholder: 'Name of the bank account',
        message: (value: string) =>
          18 - value.length >= 0
            ? `${18 - value.length} characters left`
            : undefined,
      },
    },
  ];

  const formConfig4: FormConfig = [
    {
      title: 'Bank/building society account number',
      name: 'accountNumber',
      component: StyledInput,
      isRequired: true,
      props: {
        disabled: !isDirectDebit,
        placeholder: 'Account number',
      },
    },
  ];

  return (
    <>
      {!isEditPage && (
        <TitleBlock>
          <SubTitle>Direct Debit Details</SubTitle>
          <TextBlock>
            <span>
              For any queries contact our helpdesk at{' '}
              <a href="mailto:info@silverwing.co">info@silverwing.co</a>
            </span>
            <p>
              You will need your account details, which can be found for example
              on your bank statement to complete the Direct Debit Instruction
            </p>
          </TextBlock>
        </TitleBlock>
      )}
      <FormWrapper>
        <FormStepWrapper>
          <NumberInSquare>1</NumberInSquare>
          <FormStepContent>
            <SubTitle>Personal Details</SubTitle>
            {renderFormConfig(formConfig, formikInstance)}
            <FindYourAddress
              isDisabled={!isEdit}
              postCode={formikInstance.values.postcode}
              formikInstance={formikInstance}
            />
            {renderFormConfig(formConfig2, formikInstance)}
          </FormStepContent>
        </FormStepWrapper>
        <FormStepWrapper>
          <NumberInSquare>2</NumberInSquare>
          <FormStepContent>
            <SubTitle>Bank details</SubTitle>
            {renderFormConfig(formConfig3, formikInstance)}
            <InputWrap
              isRequired
              title="Branch sort code"
              hasError={
                !!formikInstance.errors.branchSortCode1 ||
                !!formikInstance.errors.branchSortCode2 ||
                !!formikInstance.errors.branchSortCode3
              }
            >
              <BranchInputWrap>
                <BrachInput
                  disabled={!isDirectDebit}
                  placeholder="XX"
                  name="branchSortCode1"
                  value={formikInstance.values.branchSortCode1}
                  onChange={formikInstance.handleChange}
                />
                <Dot />
                <BrachInput
                  disabled={!isDirectDebit}
                  placeholder="XX"
                  name="branchSortCode2"
                  value={formikInstance.values.branchSortCode2}
                  onChange={formikInstance.handleChange}
                />
                <Dot />
                <BrachInput
                  disabled={!isDirectDebit}
                  placeholder="XX"
                  name="branchSortCode3"
                  value={formikInstance.values.branchSortCode3}
                  onChange={formikInstance.handleChange}
                />
              </BranchInputWrap>
            </InputWrap>
            {renderFormConfig(formConfig4, formikInstance)}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                style={{ width: 22, height: 22, marginRight: 15 }}
                type="checkbox"
                checked={bankUnhookExceptionCheck}
                onChange={(event) => {
                  setBankUnhookExceptionCheck(event.target.checked);
                  setIsDirectDebit &&
                    setIsDirectDebit((prevState: boolean) => !prevState);
                  formikInstance.setFieldValue(
                    'bankUnhookException',
                    event.target.checked
                  );
                }}
              />
              <label style={{ fontSize: 16, color: '#102F54' }}>
                I do not have an account capable of Direct Debit
              </label>
            </div>
            {bankUnhookExceptionCheck ? (
              <ConfirmContainerDebit>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 10,
                  }}
                >
                  <div style={{ marginRight: 20 }}>
                    <WarningFilled style={{ fontSize: '30px', color: 'red' }} />
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold', marginBottom: '6px' }}>
                      By ticking the checkbox below you confirm that you have
                      received authorisation from the school to make a manual
                      payment
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 10,
                  }}
                >
                  <ErrorDiv hasError={!!formikInstance.errors?.agree}>
                    {renderFormConfig(
                      [
                        {
                          title: 'I confirm having received authorisation',
                          name: 'agree',
                          component: StyledCheckbox,
                          props: { disabled: !isEdit },
                          containerStyle: {
                            flexDirection: 'row-reverse',
                            justifyContent: 'flex-end',
                            margin: '0',
                          },
                        },
                      ],
                      formikInstance
                    )}
                  </ErrorDiv>
                </div>
              </ConfirmContainerDebit>
            ) : (
              <ConfirmContainer>
                <span style={{ fontWeight: 'bold', marginBottom: '6px' }}>
                  By clicking on the «Confirm» button below:
                </span>
                <StyledUl>
                  <li>
                    You confirm that, if the above bank account is a personal
                    account, the account is in your name
                  </li>
                  <li>
                    You confirm that you are the only signatory required to
                    authorise the Direct Debit
                  </li>
                </StyledUl>
                <ErrorDiv hasError={!!formikInstance.errors?.agree}>
                  {renderFormConfig(
                    [
                      {
                        title: 'I understand',
                        name: 'agree',
                        component: StyledCheckbox,
                        props: { disabled: !isEdit },
                        containerStyle: {
                          flexDirection: 'row-reverse',
                          justifyContent: 'flex-end',
                          margin: '0',
                        },
                      },
                    ],
                    formikInstance
                  )}
                </ErrorDiv>
              </ConfirmContainer>
            )}
            {isEditPage &&
              renderFormConfig(
                [
                  {
                    title: 'Confirm password',
                    name: 'validatePassword',
                    component: StyledInput,
                    props: {
                      disabled: !isEdit,
                      type: 'password',
                    },
                  },
                ],
                formikInstance
              )}
          </FormStepContent>
        </FormStepWrapper>
      </FormWrapper>
      <ButtonsContainer isEditPage={isEditPage}>
        {!isEditPage && (
          <StyledGrayButton
            onClick={(e) => {
              e.preventDefault();
              onButtonClickHandler && onButtonClickHandler(1);
            }}
          >
            Back
          </StyledGrayButton>
        )}
        <StyledDarkButton disabled={!isEdit} type="submit">
          Confirm
        </StyledDarkButton>
      </ButtonsContainer>
    </>
  );
}

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SubTitle = styled.h2`
  font-weight: 750;
  font-size: 20px;
  line-height: 24px;
  color: #102f54;
`;

const TextBlock = styled.div`
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: #6a7e95;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: rgba(245, 248, 251, 0.7);
  padding: 28px;
  margin-top: 27px;
`;

const FormStepWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

const FormStepContent = styled.div`
  width: 100%;
`;

const NumberInSquare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background-color: #e8f4ff;
  color: #2682de;
`;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 420px;

  &:disabled {
    background: #f2f2f2;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 15px;
`;

const StyledPostcodeInput = styled(PostcodeInput)`
  max-width: 420px;

  &:disabled {
    background: #f2f2f2;
  }
`;

const BranchInputWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BrachInput = styled(Input).attrs({ maxLength: 2 })`
  width: 60px;
  font-size: 14px;
  padding: 20px;
  &:disabled {
    background: #f2f2f2;
  }
`;

const ConfirmContainerDebit = styled.div`
  /* width: 650px; */
  border-radius: 4px;
  padding: 18px;
  background-color: #ffe4b5;
  font-size: 14px;
  box-sizing: border-box;
  margin: 20px auto;
`;

const ConfirmContainer = styled.div`
  /* width: 650px; */
  border-radius: 4px;
  padding: 18px;
  background-color: #e0e0e0;
  font-size: 14px;
  box-sizing: border-box;
  margin: 20px auto;
`;

const StyledUl = styled.ul`
  padding: 0 15px;
  margin: 0;
  margin-bottom: 10px;
  line-height: 17px;
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  background-color: #000;
  border-radius: 50%;
  margin: 0 5px;
  box-sizing: border-box;
`;

const ButtonsContainer = styled.div<{ isEditPage: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${(props) =>
    props.isEditPage ? 'flex-end' : 'space-between'};
  margin-top: 40px;
  padding-bottom: 100px;
`;

const StyledDarkButton = styled(ButtonDark)`
  padding: 18px 95px 14px;
  &:disabled {
    cursor: initial;
  }
`;

const StyledGrayButton = styled(ButtonLightGray)`
  padding: 18px 65px 14px;
`;

const ErrorDiv = styled.div<{ hasError?: boolean }>`
  box-shadow: ${(props) => (props.hasError ? '0 0 1px 1px red' : 'none')};
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  padding: 4px 4px 0 4px;
`;
